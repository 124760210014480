


























































































































import { isEqual, cloneDeep } from "lodash";
import { Component, Watch, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import VOpenHoursList from "../VOpenHoursList.vue";
import VPhotoUploader from "../../photos/VPhotoUploader.vue";

import venuesService from "@/services/venues.service";
import { mergeTimeWindows } from "@/services/validators.service";
import { nonReactive } from "@/helpers/functions";

import {
  AppSettingsModel,
  Day,
  UserModel,
  VenueModel as VenueForm,
  MediaModel,
} from "@/@types/model";

const openHoursSchema = (): Day[] => [
  {
    day: 0,
    dayCode: "sun",
    timeWindows: [],
  },
  {
    day: 1,
    dayCode: "mon",
    timeWindows: [],
  },
  {
    day: 2,
    dayCode: "tue",
    timeWindows: [],
  },
  {
    day: 3,
    dayCode: "wed",
    timeWindows: [],
  },
  {
    day: 4,
    dayCode: "thu",
    timeWindows: [],
  },
  {
    day: 5,
    dayCode: "fri",
    timeWindows: [],
  },
  {
    day: 6,
    dayCode: "sat",
    timeWindows: [],
  },
];

const formSchema = (venue: VenueForm): VenueForm => ({
  ...venue,
  logo: venue.logo || null,
  cover: venue.cover || null,
  openHours: venue.openHours.length ? venue.openHours : openHoursSchema(),
});

let cacheForm: VenueForm | null = null;

@Component({ name: "VVenueSettingsCard", components: { VPhotoUploader, VOpenHoursList } })
export default class VVenueSettingsCard extends Vue {
  language: string | null = null;

  form: VenueForm | null = null;

  isFormEqualCache: boolean = true;

  isLoading: boolean = false;
  isBusy: boolean = false;
  isSaved: boolean = false;

  @Get("auth/user") user!: UserModel;
  @Get("app/settings") appSettings!: AppSettingsModel;

  @Watch("form", { deep: true })
  onFormChange(value: VenueForm) {
    console.log(value);

    this.isSaved = false;
    this.isFormEqualCache = isEqual(nonReactive(value), cacheForm);
  }

  get translateLanguages() {
    return [this.appSettings.defaultLanguage, ...this.appSettings.alternativeLanguages];
  }

  async created() {
    this.language = this.appSettings.defaultLanguage;
    try {
      const { currentSupplier } = this.user;
      if (!currentSupplier) throw Error(`${this.$t("InternalError.SupplierNotFound")}`);

      this.isLoading = true;
      const venues = (await venuesService.getAll(currentSupplier.id)) as VenueForm[];

      const venue = venues[0];

      const form = formSchema(venue);

      this.form = form;
      cacheForm = cloneDeep(form);

      this.$emit("venue:loaded", this.form);
    } catch (error) {
      console.error(error);
    } finally {
      this.isLoading = false;
    }
  }

  handleChangeMedia(media: MediaModel | null, type: "cover" | "logo") {
    if (!this.form) return;
    this.form[type] = media;
  }

  restore() {
    this.form = cloneDeep(cacheForm);
  }

  async save() {
    if (!this.form) return;
    try {
      this.isBusy = true;
      this.isSaved = false;

      const openHours = this.form.openHours
        // Merge Time Overlapping
        .map(mergeTimeWindows);

      await venuesService.save({ ...this.form, openHours });
      this.isBusy = false;

      cacheForm = nonReactive(this.form);
      this.isFormEqualCache = true;

      this.isSaved = true;
      this.$emit("venue:saved", this.form);
    } catch (error) {
      console.error(error);
    } finally {
      this.isBusy = false;
    }
  }
}
