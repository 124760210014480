<template>
  <div class="venue-settings">
    <v-venue-settings-management />
  </div>
</template>

<script>
import VVenueSettingsManagement from "../../components/settings/VVenueSettingsManagement";

export default {
  name: "VenueSettings",

  middleware: "check-first-config",

  metaInfo() {
    return { title: this.$t("Settings.VenueSettings") };
  },

  components: {
    VVenueSettingsManagement,
  },
};
</script>

<style lang="scss" scoped>
.venue-settings {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 576px) {
  .venue-settings {
    padding-top: 60px;
  }
}
</style>
