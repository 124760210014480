







































































import moment from "moment";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Get } from "vuex-pathify";

import VOpenHoursTimePicker from "../VTimePicker.vue";
import { AppSettingsModel, Day } from "@/@types/model";

@Component({ name: "VOpenHoursList", components: { VOpenHoursTimePicker } })
export default class VOpenHoursList extends Vue {
  @Prop(Array) readonly value!: Day[];

  @Get("app/settings") appSettings!: AppSettingsModel;

  get days() {
    const firstDayIndex = this.value.findIndex(
      ({ dayCode }) => dayCode === this.appSettings.firstDayOfWeek,
    );
    return this.value.slice(firstDayIndex).concat(this.value.slice(0, firstDayIndex));
  }

  get weekDays() {
    return moment.weekdays();
  }

  handleChangeTime() {
    this.$emit("input", this.value);
  }

  handleClickAddTimeWindow(day: number) {
    const days = this.value.map((item) =>
      item.day === day
        ? {
            ...item,
            timeWindows: item.timeWindows.concat({
              start: "00:00",
              end: "00:15",
            }),
          }
        : item,
    );

    this.$emit("input", days);
  }

  handleClickDeleteTimeWindow(day: number, idx: number) {
    const days = this.value.map((item) =>
      item.day === day
        ? { ...item, timeWindows: item.timeWindows.filter((_, index) => index !== idx) }
        : item,
    );

    this.$emit("input", days);
  }
}
