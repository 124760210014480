<template>
  <div class="venue-settings-management">
    <v-venue-settings-card />
  </div>
</template>

<script>
import VVenueSettingsCard from "./cards/VVenueSettingsCard.vue";

export default {
  name: "VVenueSettingsManagement",

  components: {
    VVenueSettingsCard,
  },

  methods: {},
};
</script>
